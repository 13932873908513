<template>
  <div class="logPage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "logPage"
};
</script>